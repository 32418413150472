export const BG_COVER = `https://ik.imagekit.io/masansgaming/Pieter/D0908257-680F-49F4-A15B-9048BD43C342_QwgGyrT7K.jpg?updatedAt=1702790691913`;
export const BG_WELCOMING_SECTION = `https://ik.imagekit.io/masansgaming/Pieter/cover%20dalam_xtZ3e-nUt.jpg?updatedAt=1702790766976`;
export const BG_COUNTING = `https://ik.imagekit.io/masansgaming/Pieter/Foto%20Counting%20Days_dmCZnU3PS.jpg?updatedAt=1702261348173`;
export const BG_IG_FILTER = `https://ik.imagekit.io/masansgaming/Pieter/COVER%20PIETER%20SONIA_ZpheFswl0.jpg?updatedAt=1702986955079`;
export const BG_DESKTOP_VIEW = `https://ik.imagekit.io/masansgaming/Pieter/Foto%20Cover%20Potrait%20_%20Foto%20Cover%20Dalam%20Galeri_zjdo_G2tS.jpg?updatedAt=1702261347538`;

export const IMG_AYAT = `https://ik.imagekit.io/masansgaming/Pieter/Foto%20Footer%20Potrait_ZgOmsXNbd.jpg?updatedAt=1702261342898`;
export const IMG_LOGO = `https://user-images.githubusercontent.com/10141928/162121855-c5b68987-ca66-415e-b53a-42ac77189f29.png`;

export const IMG_MAN = `https://ik.imagekit.io/masansgaming/Pieter/Foto%20Pieter%20Potrait_FkTIu9kJg.jpg?updatedAt=1702261346649`;
export const IMG_GIRL = `https://ik.imagekit.io/masansgaming/Pieter/Foto%20Sonia%20Potrait_p7v5W6QUF9.jpg?updatedAt=1702261346673`;
export const IMG_LOVE_STORY = `https://user-images.githubusercontent.com/10141928/146289521-e97786dc-98a1-43d3-9b0f-e9353b168766.jpg`;

export const IMG_PHOTO_1 = `https://ik.imagekit.io/masansgaming/Pieter/Foto%20Cover%20Gallery%20Landscape_3VNPzHgUP.jpg?updatedAt=1702261530776`;
export const IMG_PHOTO_2 = `https://ik.imagekit.io/masansgaming/Pieter/Foto%20Cover%20Gallery%20Landscape%202_vvasHeVQK.jpg?updatedAt=1702261530526`;
export const IMG_PHOTO_CONTENT = `https://ik.imagekit.io/masansgaming/Pieter/Foto%20RSVP%20Potrait_R86XBHMy2.jpg?updatedAt=1702261343786`;

export const IMG_COVID = {
  id: `https://user-images.githubusercontent.com/103095433/241068547-20f04f29-1531-4bc2-a5e5-c7e3ce1c2805.png`,
  en: `https://user-images.githubusercontent.com/103095433/241068561-c7d17d12-c32f-44a1-834a-af73e655b0b6.jpg`,
};

export const LOGO_INVITATO = `https://ik.imagekit.io/invitatoid/global-assets/invitato_hs3nyqsuwy_.png?updatedAt=1638541184695`;
